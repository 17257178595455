import 'desvg'

/**
 * .js-svgのついた、svgを読み込んでいるimgタグをインラインSVGに変換
 */
export class Svg {
  constructor() {
    if (document.getElementsByClassName('js-svg').length === 0) return
    document.addEventListener('DOMContentLoaded', () => {
      deSVG('.js-svg', true)
    })
  }
}
