import { gsap } from 'gsap'

export class MegaMenu {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      if (document.getElementById('js-header') == null) return
      const header = document.getElementById('js-header')
      const trgAll = document.querySelectorAll('.js-mega_trg')
      const trgArr = Array.prototype.slice.call(trgAll, 0)
      const menuAll = document.querySelectorAll('.js-mega_menu')
      const menuArr = Array.prototype.slice.call(menuAll, 0)

      trgArr.forEach(function (target, index) {
        let parent = target.parentNode
        parent.addEventListener('focusin', () => {
          visibleMenu(target, index)
        })
        parent.addEventListener('focusout', () => {
          hiddenMenu(target, index)
        })
        parent.addEventListener('mouseenter', () => {
          visibleMenu(target, index)
        })
        parent.addEventListener('mouseleave', () => {
          hiddenMenu(target, index)
        })
      })

      const visibleMenu = (elm) => {
        menuArr.forEach(function (menu) {
          gsap.to(menu, 0.3, { autoAlpha: 0 })
          menu.classList.add('is-mega')
          header.classList.add('is-mega')
        })
        elm.classList.add('is-mega')
        let currentMenu = elm.nextElementSibling
        // let headerHeight = header.clientHeight
        // currentMenu.style.top = headerHeight + 'px'
        gsap.to(currentMenu, 0.3, { autoAlpha: 1 })
      }

      const hiddenMenu = (elm) => {
        menuArr.forEach(function (menu) {
          menu.classList.remove('is-mega')
          header.classList.remove('is-mega')
        })
        let currentMenu = elm.nextElementSibling
        gsap.to(currentMenu, 0.3, { autoAlpha: 0 })
        elm.classList.remove('is-mega')
      }
    })
  }
}
