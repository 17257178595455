import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import JapaneseHolidays from 'japanese-holidays'

export class EventCalendar {
  static days = ['日', '月', '火', '水', '木', '金', '土']
  constructor() {
    if (document.getElementById('event-calendar') === null || document.getElementById('event-calendar-events') === null) return
    if (document.getElementById('event-calendar-dayGrid') === null) return
    this.dayGridButton = document.getElementById('event-calendar-dayGrid')
    if (document.getElementById('event-calendar-list') === null) return
    this.listButton = document.getElementById('event-calendar-list')
    if (document.getElementById('event-calendar-prev') === null) return
    this.prevButton = document.getElementById('event-calendar-prev')
    if (document.getElementById('event-calendar-next') === null) return
    this.nextButton = document.getElementById('event-calendar-next')
    if (document.getElementById('event-calendar-year') === null) return
    this.year = document.getElementById('event-calendar-year')
    if (document.getElementById('event-calendar-month') === null) return
    this.month = document.getElementById('event-calendar-month')
    this.init(document.getElementById('event-calendar'))
  }
  setDate(dt) {
    this.year.textContent = dt.getFullYear()
    this.month.textContent = dt.getMonth() + 1
  }
  init(el) {
    const initial = new Date()
    const calendar = new Calendar(el, {
      plugins: [dayGridPlugin, listPlugin],
      initialView: document.body.clientWidth < 768 ? 'listMonth' : 'dayGridMonth',
      headerToolbar: null,
      events: eventCalendarEvents,
      locale: 'ja',
      contentHeight: 'auto',
      // initialDate: initial.setDate(1),
      fixedWeekCount: false,
      timeZone: 'Asia/Tokyo',
      // dayCellContent: (e) => {
      //   e.dayNumberText = e.dayNumberText.replace('日', '')
      // },
      allDayDidMount(info) {
        if (info.el.classList.contains('fc-list-event-time')) {
          const headers = info.el.getAttribute('headers').split(' fc-dom-')[1].split('-')
          const headerDate = headers[1] + '-' + headers[2] + '-' + headers[3]
          const date = new Date(headerDate)
          info.el.innerHTML = '<span class="number ' + date + '">' + date.getDate() + '</span>（' + EventCalendar.days[date.getDay()] + '）'
          if (date.getDay() === 6) {
            info.el.classList.add('fc-day-sat')
          }
          if (date.getDay() === 0) {
            info.el.classList.add('fc-day-sun')
          }
        }
      },
      eventDidMount(info) {
        if (info.el.classList.contains('fc-list-event')) {
          info.el.addEventListener('click', (e) => {
            e.stopPropagation()
          })
        }
        if (info.el.querySelector('.fc-event-title')) {
          const eventTitle = info.el.querySelector('.fc-event-title')
          eventTitle.innerHTML = eventTitle.textContent
        }
        if (info.el.querySelector('.fc-list-event-title a')) {
          const listEventTitle = info.el.querySelector('.fc-list-event-title a')
          listEventTitle.innerHTML = listEventTitle.textContent
        }
      },
      eventWillUnmount(info) {
        if (info.el.querySelector('.fc-event-title')) {
          const eventTitle = info.el.querySelector('.fc-event-title')
        }
      },
      dayCellDidMount(info) {
        const holiday = JapaneseHolidays.isHoliday(info.date)
        if (holiday) {
          info.el.classList.add('fc-hol')
        }
      },
    })
    calendar.render()
    this.setDate(calendar.getDate())
    let isDayGrid = true
    this.dayGridButton.classList.add('is-act')
    this.dayGridButton.addEventListener('click', () => {
      if (isDayGrid) return
      isDayGrid = true
      this.dayGridButton.classList.add('is-act')
      this.listButton.classList.remove('is-act')
      calendar.changeView('dayGridMonth')
    })
    this.listButton.addEventListener('click', () => {
      if (!isDayGrid) return
      isDayGrid = false
      this.dayGridButton.classList.remove('is-act')
      this.listButton.classList.add('is-act')
      calendar.changeView('listMonth')
    })
    this.prevButton.addEventListener('click', () => {
      calendar.prev()
      this.setDate(calendar.getDate())
    })
    this.nextButton.addEventListener('click', () => {
      calendar.next()
      this.setDate(calendar.getDate())
    })
  }
}
