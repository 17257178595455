export class Accordion {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      // トリガークラス'.js-accordion-trigger'を持つ要素を取得
      const accordionTrigger = document.querySelectorAll('.js-accordion-trigger')
      if (!accordionTrigger) return

      // 開いているアコーディオンの要素を保持する変数
      let openedAccordion = null

      for (let i = 0; i < accordionTrigger.length; i++) {
        accordionTrigger[i].addEventListener('click', (e) => {
          const currentElement = e.currentTarget
          const accordionTarget = currentElement.nextElementSibling

          if (openedAccordion && openedAccordion !== accordionTarget) {
            // 他のアコーディオンが開いている場合、閉じる
            openedAccordion.style.height = null
            openedAccordion.classList.remove('is-opened')
            openedAccordion.previousElementSibling.classList.remove('is-opened')
          }

          if (accordionTarget.style.height) {
            // 開いているアコーディオンを閉じる
            currentElement.classList.remove('is-opened')
            accordionTarget.classList.remove('is-opened')
            accordionTarget.style.height = null
            openedAccordion = null
          } else {
            // アコーディオンを開く
            currentElement.classList.add('is-opened')
            accordionTarget.classList.add('is-opened')
            accordionTarget.style.height = accordionTarget.scrollHeight + 'px'
            openedAccordion = accordionTarget
          }
        })
      }
    })
  }
}
