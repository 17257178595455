import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export class ManagerSlider {
  constructor() {
    this.sliderEl = document.getElementById('js-manager-slider')
    if (!this.sliderEl) return

    this.slider = new KeenSlider(
      this.sliderEl,
      {
        loop: true,
        slides: {
          perView: 'auto',
        },
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 3000)
          }
          slider.on('created', () => {
            slider.container.addEventListener('mouseover', () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener('mouseout', () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on('dragStarted', clearNextTimeout)
          slider.on('animationEnded', nextTimeout)
          slider.on('updated', nextTimeout)
        },
      ]
    )
  }
}
