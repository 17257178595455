// スクロールの状態により、bodyタグにクラスを付与
// is-top: スクロール位置がページの最上部
// is-middle: スクロール位置がページの最上部でも最下部でもない
// is-bottom: スクロール位置がページの最下部
// is-down: スクロールの向きが下向き
// is-up: スクロールの向きが上向き

/**
 * スクロール時に、位置や向きの状態をbodyタグにクラス付与
 */
export class ScrollStatus {
  constructor() {
    this.body = document.body
    this.currentScrollY = 0
    this.isDown = false
    this.isPosition = ''
    this.threshold = 100
    this.scroll()

    window.addEventListener('load', this.scroll.bind(this))
    window.addEventListener('scroll', this.scroll.bind(this))
    window.addEventListener('resize', this.scroll.bind(this))

    window.addEventListener('orientationchange', () => {
      setTimeout(this.scroll.bind(this), 100)
    })
  }
  scroll() {
    const scrollY = window.scrollY
    const bodyHeight = this.body.clientHeight - this.threshold
    const innerHeight = window.innerHeight
    if (this.isPosition == 'top' && scrollY > this.threshold) {
      if (scrollY + innerHeight < bodyHeight) {
        this.isPosition = 'middle'
        this.body.classList.add('is-middle')
      } else {
        this.isPosition = 'bottom'
        this.body.classList.add('is-bottom')
      }
      this.body.classList.remove('is-top')
    } else if (this.isPosition == 'middle' && (scrollY <= this.threshold || scrollY + innerHeight >= bodyHeight)) {
      if (scrollY <= this.threshold) {
        this.isPosition = 'top'
        this.body.classList.add('is-top')
      } else {
        this.isPosition = 'bottom'
        this.body.classList.add('is-bottom')
      }
      this.body.classList.remove('is-middle')
    } else if (this.isPosition == 'bottom' && scrollY + innerHeight < bodyHeight) {
      if (scrollY <= this.threshold) {
        this.isPosition = 'top'
        this.body.classList.add('is-top')
      } else {
        this.isPosition = 'middle'
        this.body.classList.add('is-middle')
      }
      this.body.classList.remove('is-bottom')
    } else {
      if (scrollY <= this.threshold) {
        this.isPosition = 'top'
        this.body.classList.add('is-top')
      } else if (scrollY + innerHeight < bodyHeight) {
        this.isPosition = 'middle'
        this.body.classList.add('is-middle')
      } else {
        this.isPosition = 'bottom'
        this.body.classList.add('is-bottom')
      }
    }

    if (this.isDown) {
      if (scrollY > this.threshold && scrollY > this.currentScrollY) {
        this.isDown = false
        this.body.classList.add('is-down')
        this.body.classList.remove('is-up')
      }
    } else {
      if (scrollY <= this.currentScrollY) {
        this.isDown = true
        this.body.classList.remove('is-down')
        this.body.classList.add('is-up')
      }
    }

    this.currentScrollY = scrollY
  }
}
