import { UaCh } from '../foundation/UserAgent'

/**
 * モバイルの際、[data-action=call]のついたタグをaタグで囲む
 */
export class TelGrant {
  constructor() {
    if (!UaCh) return
    this.telSpans = [...document.querySelectorAll('span[data-action=call]')]
    if (this.telSpans.length == 0) return
    this.telSpans.forEach((telSpan) => {
      const tel = telSpan.dataset.tel
      if (tel) {
        telSpan.outerHTML = `<a href="tel:${tel}">${telSpan.outerHTML}</a>`
      }
    })
  }
}
