import { gsap } from 'gsap'

/**
 * タブ
 */
export class Tab {
  constructor() {
    document.addEventListener(
      'DOMContentLoaded',
      function () {
        // タブに対してクリックイベントを適用
        if (document.getElementsByClassName('js-tab').length === 0) return
        const tabs = document.querySelectorAll('.js-tab')
        tabs.forEach((tab) => {
          const tabMenus = tab.querySelectorAll('.js-tab_menu')
          const tabBodies = tab.querySelectorAll('.js-tab_body')

          if (tabMenus.length === 0) return
          if (tabMenus.length !== tabBodies.length) return
          tabMenus.forEach((tabMenu, i) => {
            tabMenu.addEventListener('click', () => {
              tabMenus.forEach((tabMenu) => {
                tabMenu.classList.remove('is-act')
              })
              tabMenus[i].classList.add('is-act')

              // Gsap アニメーションを使用して TabBody の表示/非表示を切り替え
              gsap.to(tabBodies, 0.3, { display: 'none', duration: 0.3, autoAlpha: 0 })
              gsap.to(tabBodies[i], 0.3, { display: 'block', duration: 0.3, autoAlpha: 1 })
            })
          })
        })
      },
      false
    )
  }
}
