'use strict'

// import { IeModal } from './lib/IeModal'
// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IndexKvNewsSlider } from './lib/IndexKvNewsSlider'
import { EventCalendar } from './lib/EventCalendar'
// import { EventModal } from './lib/EventModal'
import { Accordion } from './lib/Accordion'
import { TextSplit } from './lib/TextSplit'
import { AnchorSidebar } from './lib/AnchorSidebar'
import { MemberEventSlider } from './lib/MemberEventSlider'
import { ManagerSlider } from './lib/ManagerSlider'
import { Tab } from './lib/Tab'
import { MegaMenu } from './lib/MegaMenu'
import { FixedHeader } from './lib/fixedHeader'

// new IeModal('サイト名', 'http://hoge.com')
// new CookiePolicy('optout')
new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new IndexKvNewsSlider()
new EventCalendar()
// new EventModal()
new Accordion()
new TextSplit()
new AnchorSidebar()
new MemberEventSlider()
new ManagerSlider()
new Tab()
new MegaMenu()
new FixedHeader()
