import gsap, { Power2 } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

/**
 * アンカーリンクをなめらかにスクロール。
 * ページ読み込み時にハッシュがついている時、そのIDのタグがあれば同じくスクロール
 */
export class Smooth {
  constructor() {
    const hash = location.hash
    if (hash) {
      const hashTarget = document.querySelector(hash)
      if (hashTarget !== null) {
        gsap.to(window, {
          duration: 1,
          ease: Power2.easeInOut,
          scrollTo: {
            y: hashTarget,
            offsetY: 0,
          },
        })
      }
    }

    this.smoothScrollTriggers = [...document.querySelectorAll('a[href^="#"]')]

    this.header = document.getElementById('js-header')
    if (this.smoothScrollTriggers.length === 0) return

    this.smoothScrollTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault()
        gsap.to(window, {
          duration: 1,
          ease: Power2.easeInOut,
          scrollTo: {
            y: trigger.getAttribute('href') === '#' ? 0 : trigger.getAttribute('href'),
            offsetY: this.header ? this.header.clientHeight : 0,
          },
        })
      })
    })
  }
}
