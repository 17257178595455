import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export class IndexKvNewsSlider {
  constructor() {
    this.sliderEl = document.getElementById('js-index-news-slider')
    if (!this.sliderEl) return

    // pager作成
    const slides = this.sliderEl.children
    const slideLength = slides.length
    const pagination = document.createElement('ul')
    pagination.classList.add('c-slider_pagination')
    for (var i = 0; i < slideLength; i++) {
      pagination.appendChild(document.createElement('li'))
      pagination.children[i].setAttribute('data-target', i)
    }
    const pager = pagination.children
    this.sliderEl.parentNode.appendChild(pagination)

    // slider作成
    this.slider = new KeenSlider(
      this.sliderEl,
      {
        loop: true,
        renderMode: 'custom',
        defaultAnimation: {
          duration: 3000,
        },
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 3000)
          }
          slider.on('created', (s) => {
            pager[s.track.details.rel].classList.add('is-act')
            s.slides[s.track.details.rel].classList.add('is-act')
            setTimeout(() => {
              s.update()
            }, 100)
          })
          slider.on('slideChanged', (s) => {
            s.slides.forEach((slide) => {
              slide.classList.remove('is-act')
            })
            s.slides[s.track.details.rel].classList.add('is-act')
            for (var i = 0; i < slideLength; i++) {
              if (s.track.details.rel == i) {
                pager[i].classList.add('is-act')
              } else {
                pager[i].classList.remove('is-act')
              }
            }
          })
          slider.on('detailsChanged', (s) => {
            nextTimeout()
            // s.slides.forEach((element, idx) => {
            //   element.style.opacity = s.track.details.slides[idx].portion
            // })
          })
          slider.on('animationEnded', nextTimeout)
          slider.on('updated', nextTimeout)
        },
      ]
    )
  }
}
