export class SliderParts {
  constructor(slider) {
    this.slider = slider
    const [bar, progress] = this.createBar()
    this.bar = bar
    this.prevButton = this.createButton('-prev')
    this.nextButton = this.createButton('-next')
    this.indicator = document.createElement('div')
    this.indicator.className = 'c-slider__indicator'
    this.dots = []
    for (let i = 0; i < this.slider.slides.length; i++) {
      this.dots.push(this.createDot(i === this.slider.track.details.rel))
      this.indicator.appendChild(this.dots[i])
    }

    if (this.slider.track.details.maxIdx === 0) return

    if (!this.slider.options.loop) {
      this.prevButton.disabled = this.slider.track.details.rel === 0
    }

    if (this.slider.container.dataset.indicator !== undefined) {
      this.dots.forEach((dot, i) => {
        dot.addEventListener('click', () => {
          this.slider.moveToIdx(i)
        })
      })
    }
    if (this.slider.container.dataset.progress !== undefined) {
      progress.style.width = `${100 / (this.slider.track.details.maxIdx + 1)}%`
    }

    if (this.slider.container.dataset.nav !== undefined) {
      this.prevButton.addEventListener('click', this.slider.prev)
      this.nextButton.addEventListener('click', this.slider.next)
    }

    this.slider.on('slideChanged', () => {
      this.dots.forEach((dot, i) => {
        dot.disabled = i === this.slider.track.details.rel
      })
      if (this.slider.options.loop) return
      this.prevButton.disabled = this.slider.track.details.rel === 0
      this.nextButton.disabled = this.slider.track.details.rel === this.slider.track.details.maxIdx
    })

    this.slider.on('detailsChanged', () => {
      const percent = Math.max(0, Math.min(1, this.slider.track.details.progress)) * 100
      progress.style.left = `${percent}%`
      progress.style.transform = `translate3d(${-percent}%,0px,0px)`
    })
  }
  createBar() {
    const bar = document.createElement('div')
    bar.className = 'c-slider__bar'
    const progress = document.createElement('span')
    bar.appendChild(progress)
    return [bar, progress]
  }
  createButton(className) {
    const button = document.createElement('button')
    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="currentColor" d="M89.45 87.5l143.1 152c4.375 4.625 6.562 10.56 6.562 16.5c0 5.937-2.188 11.87-6.562 16.5l-143.1 152C80.33 434.1 65.14 434.5 55.52 425.4c-9.688-9.125-10.03-24.38-.9375-33.94l128.4-135.5l-128.4-135.5C45.49 110.9 45.83 95.75 55.52 86.56C65.14 77.47 80.33 77.87 89.45 87.5z"/></svg>`
    button.setAttribute('type', 'button')
    button.className = 'c-slider__nav ' + className
    return button
  }
  createDot(bool) {
    const button = document.createElement('button')
    button.setAttribute('type', 'button')
    button.className = 'c-slider__indicator__dot'
    button.disabled = bool
    return button
  }
}
