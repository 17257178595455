import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { SliderParts } from './SliderParts'

export class MemberEventSlider {
  constructor() {
    this.slidersEl = [...document.getElementsByClassName('js-member-event-slider')]
    if (this.slidersEl.length === 0) return
    this.sliders = this.slidersEl.map((sliderEl) => {
      sliderEl.classList.add('keen-slider')
      if (sliderEl.dataset.twice !== undefined) {
        sliderEl.innerHTML += sliderEl.innerHTML
      }
      const slides = [...sliderEl.children]
      slides.forEach((slide) => {
        slide.classList.add('keen-slider__slide')
      })
      return new KeenSlider(sliderEl, {
        slides: {
          perView: 'auto',
        },
        loop: sliderEl.dataset.loop !== undefined,
        created: (s) => {
          const parts = new SliderParts(s)
          if (s.container.dataset.nav !== undefined) {
            const controls = document.createElement('div')
            controls.className = 'c-slider__controls'
            s.container.parentNode.appendChild(controls)
            if (s.container.dataset.progress !== undefined) {
              controls.appendChild(parts.bar)
            }
            if (s.container.dataset.nav !== undefined) {
              controls.appendChild(parts.prevButton)
              controls.appendChild(parts.nextButton)
            }
          }
        },
      })
    })
  }
}
