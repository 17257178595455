import getAgent from '@egjs/agent'
const agent = getAgent()

/**
 * User Agent Client Hint
 * @property {AgentInfo} agent egjs/agent
 * @property {boolean} isMobile モバイル判定
 * @property {string} browser ブラウザ名
 */
export const UaCh = {
  agent,
  isMobile: agent.isMobile,
  browser: agent.browser.name,
}
