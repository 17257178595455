export class FixedHeader {
  constructor() {
    const header = document.getElementById('js-header')
    let targetPosition = 0
    if (window.innerWidth > 768) {
      targetPosition = 0
    } else {
      targetPosition = 10
    }
    window.addEventListener('scroll', (e) => {
      var y = window.pageYOffset
      if (y > targetPosition) {
        header.classList.add('is-fixed')
      } else {
        header.classList.remove('is-fixed')
      }
    })

    document.addEventListener('DOMContentLoaded', () => {
      if (location.hash) {
        const headerHeight = document.getElementById('js-header').clientHeight
        setTimeout(() => {
          window.scrollBy(0, -headerHeight)
        }, 100)
      }
    })
  }
}
