import StickySidebar from 'sticky-sidebar'

export class AnchorSidebar {
  constructor() {
    const elm = document.querySelector('.js-sidebar')

    if (!elm) return

    let topSpacingValue = 300

    if (elm.classList.contains('-regular')) {
      topSpacingValue = 100
    }

    const sidebar = new StickySidebar(elm, {
      containerSelector: '.js-sidebar_wrap',
      innerWrapperSelector: '.js-sidebar_inner',
      topSpacing: topSpacingValue,
    })

    const mediaQuery = matchMedia('(min-width: 767px)')
    const mediaQueryHandler = (mq) => {
      if (mq.matches) {
        // Sticky Sidebar有効化
        sidebar.initialize()
      } else {
        // Sticky Sidebar無効化
        elm.style.position = 'static'
        elm.style.width = 'auto'
        sidebar.destroy()
      }
    }

    window.addEventListener('load', () => {
      mediaQueryHandler(mediaQuery)
      mediaQuery.addListener(mediaQueryHandler)
    })

    // アンカーと同期

    const anchor = document.querySelector('.js-anchor')
    const sectionList = document.querySelectorAll('.js-anchor_section')

    let isAnimation = false

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    }

    // 交差した時に実行する関数
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (isAnimation) return
          activateIndex(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(callback, options)

    let html = ''
    sectionList.forEach((section, i) => {
      const dataTitle = section.dataset.title
      const dataYear = section.dataset.year
      const id = 'no0' + (i + 1)

      section.setAttribute('id', id)

      if (dataYear) {
        html +=
          '<li><a href="#' +
          id +
          '" class="js-anchor_item">' +
          '<span class="en c-font_din -demi">' +
          dataYear +
          '</span>' +
          '<span class="c-title01 -fz2_4 -dot u-weight-600">' +
          dataTitle +
          '</span></a></li>'
      } else {
        html += '<li><a href="#' + id + '" class="js-anchor_item u-weight-500">' + dataTitle + '</a></li>'
      }

      observer.observe(section)
    })
    anchor.innerHTML += html

    const anchorItem = document.querySelectorAll('.js-anchor_item')

    // 目次のスタイルを変える関数
    const activateIndex = (item) => {
      const currentActive = document.querySelector('.js-anchor_item.is-current')
      if (currentActive !== null) {
        currentActive.classList.remove('is-current')
      }
      const newActive = document.querySelector(`a[href='#${item.id}']`)
      newActive.classList.add('is-current')
    }

    const active = (item) => {
      item.classList.add('is-current')
      anchorItem.forEach((elm) => {
        if (elm !== item) elm.classList.remove('is-current')
      })
    }

    anchorItem.forEach((item) => {
      item.addEventListener('click', (event) => {
        const target = event.currentTarget
        isAnimation = true
        active(target)
        setTimeout(() => {
          isAnimation = false
        }, 500)
      })
    })
  }
}
